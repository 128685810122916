import React, { useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import { Container, styled } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";
import { FaRegPlayCircle } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoCodeSharp } from "react-icons/io5";

const DeployModel = () => {
  const [openAction, setOpenAction] = useState(false);

  const [showActionsForModel, setShowActionsForModel] = useState(null);
  const navigate = useNavigate();

  const handleAction = (modelId) => {
    setShowActionsForModel(showActionsForModel === modelId ? null : modelId);
  };

  return (
    <div className="flex w-full min-h-screen">
      {/* Sidebar Section */}
      <Sidebar className="flex-shrink-0 h-full fixed" />
      <div className="flex flex-col w-full">
        <div className="ml-[90px]">
          {/* Navbar Section */}
          <Navbar className="flex-shrink-0 fixed w-full" />
        </div>
        {/* page content */}
        <div className="ml-[90px] px-2 pt-[84px] mb-24">
          {/* Model Information Section */}
          <Container sx={{ maxWidth: { xs: "100%", sm: "1252px" } }}>
            {/* Breadcrumbs */}
            <div className="flex justify-between py-4">
              <div>
                <div className="flex items-center">
                  <Link to="/modelStudio">
                    <h2
                      className="text-xl font-bold text-heading-black hover:text-blue-500"
                      onClick={() => navigate("/modelStudio")}
                    >
                      Model Studio
                    </h2>
                  </Link>
                  <MdKeyboardArrowRight className="text-2xl mt-1" />
                  <Link to={`/deploy`}>
                    <h2 className="text-xl font-bold text-heading-black hover:text-blue-500">
                      Deployed Models
                    </h2>
                  </Link>
                </div>
                <p className="text-base font-normal text-gray-70 pt-1">
                  Deploy Tuned AI models
                </p>
              </div>
            </div>
            {/* Deploy Models Table */}
            <table className="w-full">
              <thead className="bg-table-header-bg-color">
                <tr>
                  <th className="py-4 px-10 text-sm font-semibold text-left">
                    Model Name
                  </th>
                  <th className="py-4 px-10 text-sm font-semibold text-left">
                    Category
                  </th>
                  <th className="py-4 px-10 text-sm font-semibold text-left">
                    Status
                  </th>
                  <th className="py-4 px-10 text-sm font-semibold text-left">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {models.map((model, i) => ( */}
                <tr
                  // key={model._id}
                  // className={`${
                  //   i % 2 === 0 ? "bg-white" : "bg-light-gray-1"
                  // } w-full`}
                  className="w-full"
                >
                  <td className="py-4 px-10 text-sm font-normal">
                    {/* {model.model_name} */}
                    Model_name_01
                  </td>
                  <td className="py-4 px-10 text-sm font-normal">
                    {/* {model.feature.gpu_name} */}
                    Text Generation
                  </td>

                  <td className="py-4 px-10 text-sm font-normal">
                    {/* {model.status} */}
                    In-progress
                  </td>
                  <td>
                    <HiDotsVertical
                      className="mt-0.5 ml-12 cursor-pointer"
                      // onClick={() => handleAction(model._id)}
                      onClick={() => handleAction(1)}
                    />
                    {/* Dropdown buttons based on the model status */}
                    {
                      // showActionsForModel === model._id
                      showActionsForModel && (
                        <div className="absolute z-10 bg-white shadow-lg rounded-md p-2 mt-2 w-48  ring-1 ring-black ring-opacity-5">
                          <button
                            //   className={`flex items-center w-full text-base px-4 py-2
                            //      ${model.status === 'created'
                            //       ? "text-black"
                            //       : "text-gray-400 cursor-not-allowed"
                            //   }`
                            // }
                            //   disabled={model.status !== 'created'}
                            //   onClick={() => viewDetails(model)}
                            className="flex items-center w-full text-base px-4 py-2"
                          >
                            <FaRegPlayCircle className="mr-2 h-5 w-5" /> Start /
                            Stop
                          </button>
                          <button
                            // className={`flex items-center w-full text-base px-4 py-2 ${
                            //   model.status === 'Completed'
                            //     ? "text-black"
                            //     : "text-gray-400 cursor-not-allowed"
                            // }`}
                            // disabled={model.status !== 'Completed'}
                            // onClick={() => deployModel(model._id)}
                            className="flex items-center w-full text-base px-4 py-2"
                          >
                            {/* <TbExternalLinkOff className="mr-2 h-5 w-5 text-gray-400" />{" "} */}
                            <FaRegTrashAlt className="mr-2 h-5 w-5" /> Delete
                          </button>
                          <button
                            // className={`flex items-center w-full text-base px-4 py-2 ${
                            //   model.status === 'completed'
                            //     ? "text-black"
                            //     : "text-gray-400 cursor-not-allowed"
                            // }`}
                            // disabled={model.status !== 'Completed'}
                            // onClick={() => deleteModel(model._id)}
                            className="flex items-center w-full text-base px-4 py-2"
                          >
                            <IoCodeSharp className="mr-2 h-5 w-5" /> Get Code
                          </button>
                        </div>
                      )
                    }
                  </td>
                </tr>
                {/* ))} */}
              </tbody>
            </table>
          </Container>
        </div>
        {/* Footer Section */}
        <div className={`ml-[90px]`}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DeployModel;
