import React, { useContext, useState, useEffect, createContext } from "react";
import { FiUpload } from "react-icons/fi";
import {
  FormControl,
  TextField,
  Box,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import Tooltip from "../../../Tooltip";
// import UploadCSVModal from "./UploadCSVModal";
import FineTuneModelModal from "./FineTuneModelModal";
import axios from "axios";
import axiosInstance from "../../../api/axios";
import { useData } from "./ModelContext";
import { useNavigate } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import { toast } from "react-toastify";
import {
  getInstanceStatus,
  getTextGeneration,
  getStatusLight,
  getInstanceStatusStopper,
} from "../../../services/api";
import AddCreditModal from "../../AddCreditModal";
const ModelTuning = ({ huggingface, setPlanId, setModalInfo, modelData }) => {
  const [modelName, setModelName] = useState("");
  const [description, setDescription] = useState("");
  const [gpuPlans, setGpuPlans] = useState({});
  const [gpu, setGpu] = useState(null);
  const [gpuCount, setGpuCount] = useState(1);
  const [totalCost, setTotalCost] = useState(0);
  const [epochs, setEpochs] = useState(2);
  const [maxSteps, setMaxSteps] = useState(100);
  const [optimizationMethod, setOptimizationMethod] = useState("4-bit");
  const [validationPercent, setValidationPercent] = useState("10");
  const [dataCategory, setDataCategory] = useState("qa");
  const [learningRate, setLearningRate] = useState(0.0002);
  const [loraRank, setLoraRank] = useState(4);
  const [targetLayers, setTargetLayers] = useState("self_attn");
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [userPromt, setuserPromt] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const [apiError, setApiError] = useState(null); // Added API error state
  const navigate = useNavigate();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreditOpen, setIsCreditOpen] = useState(false);

  // const handleOutputData = (inputColumns, outputColumns) => {
  //   setInputColumns(inputColumns);
  //   setOutputColumns(outputColumns);
  // }
  // Fetch GPU plans on component mount
  //jjj
  useEffect(() => {
    const fetchGpuPlans = async () => {
      try {
        const response = await axiosInstance.get("/nocode/gpu_plans");
        setGpuPlans(response.data.data);
      } catch (error) {
        console.error("Error fetching GPU plans:", error);
      }
    };

    fetchGpuPlans();
  }, [huggingface]);

  // Calculate total cost when GPU or GPU count changes
  useEffect(() => {
    const firstGpu = Object.keys(gpuPlans)[0];
    if (!gpu && firstGpu) {
      setGpu(firstGpu);
    }
    if (gpu && gpuCount) {
      const selectedGpuPlan = gpuPlans[gpu]?.find(
        (plan) => plan.gpu_count === gpuCount
      );
      if (selectedGpuPlan) {
        setTotalCost(selectedGpuPlan.price);
      }
    }
  }, [gpu, gpuCount, gpuPlans]);

  const handleGpuChange = (event) => {
    setGpu(event.target.value);
  };

  const handleGpuCountChange = (event) => {
    setGpuCount(event.target.value);
  };

  // Example options for each field
  const learningRates = ["0.001", "0.01", "0.1", "0.5", "1.0"];
  const loraRanks = ["1", "2", "4", "8", "16"];
  const targetLayersOptions = ["Layer 1", "Layer 2", "Layer 3", "Layer 4"];

  const optimizationMethods = ["Adam", "SGD", "RMSprop"];

  const handleClick = () => {
    setIsOpen(true);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!modelName) newErrors.modelName = "Model Name is required";
    // if (!description) newErrors.description = "Description is required";
    if (!gpu) newErrors.gpu = "Gpu is requried";
    if (!epochs) newErrors.epochs = "Epochs are required";
    if (!maxSteps) newErrors.maxSteps = "Max Steps are required";
    if (!optimizationMethod)
      newErrors.optimizationMethod = "Optimization Method is required";
    if (!learningRate) newErrors.learningRate = "Learning Rate is required";
    if (!loraRank) newErrors.loraRank = "LoRA Rank is required";
    if (!targetLayers) newErrors.targetLayers = "Target Layers are required";
    if (!validationPercent)
      newErrors.validationPercent = "Validation Percentage is required";
    if (!dataCategory) newErrors.dataCategory = "Data Category is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  // const handleTune = () => {
  //   if (validateFields()) {
  //     console.log("Tuning model with the following parameters:", {
  //       modelName,
  //       description,
  //       gpu,
  //       epochs,
  //       maxSteps,
  //       optimizationMethod,
  //       learningRate,
  //       loraRank,
  //       targetLayers,
  //     });
  //     setSnackbarOpen(true);
  //   }
  // };
const checkBalance = async () => {
  try {
    // Step 1: Check for available credits (GET request)
    const rescheck = await axiosInstance.get(
      `payments/availableCredit/?model-name=${modelName}`
    );

    if (rescheck.data.data.status) {
      // Step 2: Credit is available, proceed to deduct it (POST request)
      const deductCredit = await axiosInstance.post(
        "payments/availableCredit/",
        {
          model_name: modelName,
          add: false,
          sub: true,
          amount: 0,
        }
      );

      // if (deductCredit.data.data.status) {
      //   // toast.warning(deductCredit.data.message);
      //   // Proceed with the instance status check since credit deduction was successful
      //   const res = await getInstanceStatus({ instance_id: ins_id });
      //   console.log(res);

      //   if (res.status === 200) {
      //     setLoading(true);
      //     setInitialising(false);

      //     try {
      //       const responseLight = await getStatusLight({
      //         instance_id: ins_id,
      //       });
      //       setModelStatusLight(responseLight.data.status);
      //     } catch (error) {
      //       console.log(error.message);
      //       await refundCredit(); // Refund credit if something goes wrong
      //     }

      //     // Trigger handleClick for text generation
      //     handleClick(deductCredit);
      //   }
      // } else {
      //   // Failed to deduct credit, stop further execution
      //   toast.error(deductCredit.data.message);
      //   setLoading(false);
      //   setInitialising(false);
      //   return false; // Stop further execution
      // }
    } else {
      // If no credit, show warning and stop the process
      toast.error(rescheck.data.message);
      setIsOpen(true); // Open AddCreditModal when no credit
      setLoading(false);
      // setInitialising(false);
      return false; // Stop further execution
    }
  } catch (error) {
    // toast.error("Something Went Wrong,Please Try Again!");
    setLoading(false);
    // setInitialising(false);
  }
}
  const handleTune = async () => {
    if (!validateFields()) {
      return;
    }
    if (!fileUrl) {
      toast.error("Please upload a CSV file to Tune");
      return;
    }

    setLoading(true); // Start loading
    setApiError(null); // Reset API error

    // Prepare the payload
    const payload = {
      s3_url: fileUrl,
      model_id: huggingface,
      df_category: dataCategory,
      feature_cols: inputColumns,
      target_cols: outputColumns,
      optimize: optimizationMethod,
      lora_rank: Number(loraRank),
      validation_percent: validationPercent,
      max_steps: Number(maxSteps),
      epochs: Number(epochs),
      learning_rate: Number(learningRate),
      model_name: modelName,
      description: description,
      gpu_name: gpu,
      number_of_gpu: gpuCount,
      price: totalCost,
      df_prompt: userPromt,
      target_layer: targetLayers,
    };
    console.log(payload);

    try {
      setLoading(true); // Start loading

      // Fire the API call but do not await its result
      axiosInstance
        .post(`/tune/deploy`, payload) //changes
        .then((response) => {
          toast(response.data.message);
          if (response.data.message === 'Insufficient credits.') {
            setIsCreditOpen(true);
          }
          console.log("Model tuning initiated:", response.data);
        })
        .catch((error) => {
          console.error("Error tuning model:", error);
        });

      // Immediately show snackbar (if required)
      setSnackbarOpen(true);

      // Wait for 5 seconds (or any delay you want) before navigating
      await new Promise((resolve) => setTimeout(resolve, 10000));

      // Immediately navigate without waiting for the API response
      navigate("/myInstance", { state: { openTab: 2 } });
    } catch (error) {
      setApiError("An unexpected error occurred.");
    } finally {
      setLoading(false); // End loading
    }
  };

  const launchInstance = async (ele) => {
    setIsLoading(true);
    const requestData = {
      allocatePublicIp: true,
      instanceType: "gpu",
      resourceLevel: ele.instance,
      modelPath: ele.path,
      plan: ele.id,
      plan_name: ele.name,
      amount: ele.price,
    };

    setTimeout(() => {
      setIsLoading(false);
      navigate("/myInstance");
    }, 5000);

    const secondResponse = await axiosInstance.post(
      `/manage/launch-instance`,
      requestData
    );

    if (secondResponse.status === 200) {
      console.log(secondResponse.data.notebookUrl);
      toast(secondResponse.data.message);
      setIsLoading(false);
      return secondResponse.data.notebookUrl; // Return the notebook URL
    } else {
      console.log("Error in launchInstance request");
      setIsLoading(false);
      return null; // Failed to launch instance or no notebook URL
    }
  };

  const handleCPU = async (ele) => {
    setPlanId(ele.id);
    setModalInfo(ele);
    setIsLoading(true); // Indicate loading state

    try {
      // Attempt to activate the plan and get the notebook URL
      const notebookUrl = await launchInstance(ele);

      // If notebookUrl is returned, open it in a new tab
      if (notebookUrl) {
        window.open(notebookUrl, "_blank");

        // Optionally reload UI data
        // loadCard();  // Uncomment if you need to reload card data
      } else {
        throw new Error("Failed to launch instance");
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      // Always turn off loading state
      setIsLoading(false);
    }
  };

  const deployAiModel = () => {
    handleCPU(modelData);
  };
  const handleConfirmation = () => {
    setConfirmationOpen(true);
  };

  const handleConfirmTune = () => {
    setConfirmationOpen(false);
    //checkBalance();
    handleTune();
  };

  const handleCancelTune = () => {
    setConfirmationOpen(false);
  };
  const { inputColumns, outputColumns, fileUrl } = useData();
  // console.log(inputColumns, outputColumns);
  console.log(fileUrl);

  const learnMoreLink = (
    <a
      href="https://www.qubrid.com/getting-started-with-no-code-llm-fine-tuning/"
      target="_blank"
      rel="noopener noreferrer"
      className="flex justify-between items-center text-[#8F27C1]"
    >
      Learn more <FiExternalLink className="ml-1" />
    </a>
  );
  const maxRows = Math.max(inputColumns.length, outputColumns.length);
  return (
    <div className="min-h-[538px] w-full bg-white shadow-md p-8">
      <section className="flex flex-col sm:flex-row sm:justify-between">
        <div className="sm:mr-10 mb-4 sm:mb-0">
          <h5 className="font-semibold text-base mb-4 sm:mb-8">
            Select data for tuning
          </h5>
          <p className="text-base font-normal -mt-2 sm:-mt-6 mb-6">
            Optimize your machine learning models without writing any code.
          </p>
        </div>
        
        <button
          className="classic-button mr-10 flex items-center justify-center mt-2 mb-4 sm:mt-4 h-[45px] min-w-[181px] text-[16px] font-semibold"
          onClick={handleClick}
        >
          <FiUpload className="mr-2" />
          <span>Upload CSV File</span>
        </button>
      </section>

      {/* Output data to be added in future */}
      {(inputColumns.length > 0 || outputColumns.length > 0) && (
        <section>
          <p className="font-semibold">
            The model only gets one input and output, so if there are multiple
            inputs or multiple outputs, they will be merged for tuning.
          </p>
          <table className="min-w-full border rounded-md text-center mb-4 mt-2 pb-4 mx-auto">
            <thead className="bg-[#EFF0F8CC]">
              <tr className="text-center text-sm text-[#10001A]">
                <th className="py-2 px-4">Input</th>
                <th className="py-2 px-4">Output</th>
              </tr>
            </thead>
            <tbody>
              {[...Array(maxRows)].map((_, index) => (
                <tr
                  key={index}
                  className={`bg-white hover:bg-gray-100 text-[#10001A] ${
                    index % 2 === 1 ? "bg-[#F8F9FD80]" : ""
                  }`}
                >
                  <td className="py-2 px-4 text-center text-gray-700">
                    {inputColumns[index] || "-"}
                  </td>
                  <td className="py-2 px-4 text-center text-gray-700">
                    {outputColumns[index] || "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      )}

      {/* Model Information */}
      <section className="mb-6">
        <p className="flex items-center space-x-1 mb-6 ">
          <span className="font-semibold text-base">Model Information</span>
          {/* <Tooltip text="The pre-loaded model to use for text generation." /> */}
          <Tooltip text="Provide the model name and a brief description of its purpose, task, or dataset" />
          {/* <span className="flex justify-between items-center text-[#8F27C1]">Learn more <FiExternalLink className="ml-1"/></span> */}
        </p>
        <Box className="grid grid-cols-1 gap-6">
          <Box className="grid grid-cols-1 sm:grid-cols-2 gap-6 md:mr-[14%]">
            {/* Model Name Input */}
            <TextField
              id="model-name"
              label={
                <span>
                  Model Name<span className="text-red-500">*</span>
                </span>
              }
              className="w-auto xl:w-[470px]"
              error={!!errors.modelName}
              helperText={errors.modelName}
              onChange={(e) => setModelName(e.target.value)}
            />

            {/* Select Model */}
            <FormControl
              className="w-auto md:w-[450px] lg:w-[468px]"
              disabled
              sx={{ opacity: 0.8 }}
            >
              <InputLabel
              // sx={{ fontSize: "1.2rem" }}
              >
                Select Model
              </InputLabel>
              <Select
                label="Select Model"
                defaultValue="huggingface"
                sx={{ height: 44 }}
              >
                <MenuItem value="huggingface">{huggingface}</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Description Input */}
          <TextField
            id="model-description"
            label={<span>Description</span>}
            // InputLabelProps={{
            //   sx: { fontSize: "1.2rem" },
            //   shrink: true,
            // }}
            defaultValue="This model is designed to perform various tasks (generating creative text, answering questions, or translating languages). Tuning will be used to improve its overall performance and accuracy."
            multiline
            rows={4}
            className="w-full"
            error={!!errors.description}
            helperText={errors.description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Box>
      </section>

      {/* Hardware Configuration */}
      <section className="mb-6 sm:mr-12 md:mr-1 xl:mr-12">
        <p className="flex items-center space-x-1 mb-6">
          <span className="font-semibold text-base">
            Hardware Configuration
          </span>
          <Tooltip text="Select GPU: Higher GPUs provide faster training" />
        </p>
        <Box className="flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6 items-center font-[18px]">
          {/* GPU Selection */}
          <FormControl className="w-full lg:w-[50%]">
            <InputLabel
            // sx={{
            //   fontSize: "1.2rem",
            //   marginRight: "16px",
            // }}
            >
              <span>
                GPU Selection<span className="text-red-500">*</span>
              </span>
            </InputLabel>
            <Select
              label="GPU Selection"
              value={gpu || ""}
              onChange={handleGpuChange}
              error={!!errors.gpu}
              helperText={errors.gpu}
              sx={{ height: 44 }}
            >
              {Object.keys(gpuPlans).map((gpuName) => (
                <MenuItem key={gpuName} value={gpuName}>
                  {gpuName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Number of GPUs */}
          <FormControl
            className="w-full lg:w-1/2"
            disabled
            sx={{ opacity: 0.8 }}
          >
            <InputLabel
            // sx={{
            //   fontSize: "1.2rem",
            //   marginRight: "16px",
            // }}
            >
              No of GPUs
            </InputLabel>
            <Select
              label="No of GPUs"
              value={gpuCount}
              onChange={handleGpuCountChange}
              sx={{ height: 44 }}
            >
              {[1].map((count) => (
                <MenuItem key={count} value={count}>
                  {count}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Cost Display */}
          <Box className="flex items-center justify-center bg-gray-100 rounded-md p-2 w-full sm:w-auto min-w-[100px]">
            <Typography className="text-green-600 font-bold">
              ${totalCost} / hr
            </Typography>
          </Box>
        </Box>
      </section>

      {/* Training Configuration */}
      <section className="mb-6 sm:mr-0 md:mr-[15%]">
        <p className="flex items-center space-x-1 mb-6">
          <span className="font-semibold text-base">
            Training Configuration
          </span>
          {/* <Tooltip text="Directory name to save the model outputs." /> */}
          {learnMoreLink}
        </p>
        <Box className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
          {/* Epochs Input */}
          <TextField
            label={
              <span>
                Epochs<span className="text-red-500">*</span>
              </span>
            }
            // InputLabelProps={{
            //   sx: { fontSize: "1.2rem" },
            //   shrink: true,
            // }}
            defaultValue="2"
            className="w-auto xl:w-[470px]"
            error={!!errors.epochs}
            helperText={errors.epochs}
            onChange={(e) => setEpochs(e.target.value)}
          />
          {/* Training Max Steps Input */}
          <TextField
            label={
              <span>
                Training Max Step<span className="text-red-500">*</span>
              </span>
            }
            // InputLabelProps={{
            //   sx: { fontSize: "1.2rem" },
            //   shrink: true,
            // }}
            defaultValue="100"
            className="w-auto xl:w-[470px]"
            error={!!errors.maxSteps}
            helperText={errors.maxSteps}
            onChange={(e) => setMaxSteps(e.target.value)}
          />
          {/* Optimization Method Autocomplete */}
          <FormControl
            className="w-auto xl:w-[470px]"
            disabled
            sx={{ opacity: 0.8 }}
          >
            <InputLabel
            // sx={{
            //   fontSize: "1.2rem",
            //   marginRight: "16px",
            // }}
            >
              <span>
                Optimization Method<span className="text-red-500">*</span>
              </span>
            </InputLabel>
            <Select
              label="Optimization Method"
              defaultValue="4-bit"
              sx={{ height: 44 }}
              error={!!errors.optimizationMethod}
              helperText={errors.optimizationMethod}
              onChange={(e) => setOptimizationMethod(e.target.value)}
            >
              <MenuItem value="4-bit">4-bit</MenuItem>
              {/* <MenuItem value="8-bit">8-bit</MenuItem> */}
            </Select>
          </FormControl>
        </Box>
      </section>

      {/* Advanced Settings */}
      <section className="mb-6 sm:mr-0 md:mr-[15%]">
        <p className="flex items-center space-x-1 mb-6">
          <span className="font-semibold text-base">Advanced Settings</span>
          {/* <Tooltip text="LoRA rank (size of low-rank matrices)." /> */}
          {learnMoreLink}
        </p>
        <Box className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-6 mt-4">
          {/* Lora Rank Field */}
          <FormControl className="w-auto xl:w-[470px]">
            <InputLabel
            // sx={{
            //   fontSize: "1.2rem",
            //   marginRight: "16px",
            // }}
            >
              <span>
                Lora Rank<span className="text-red-500">*</span>
              </span>
            </InputLabel>
            <Select
              label="Lora Rank"
              defaultValue="4"
              sx={{ height: 44 }}
              error={!!errors.loraRank}
              helperText={errors.loraRank}
              onChange={(e) => setLoraRank(e.target.value)}
            >
              <MenuItem value="1">4</MenuItem>
              <MenuItem value="2">8</MenuItem>
              <MenuItem value="3">16</MenuItem>
              <MenuItem value="4">32</MenuItem>
              <MenuItem value="5">64</MenuItem>
            </Select>
          </FormControl>

          {/* Target Layers Field */}
          <FormControl
            className="w-auto xl:w-[470px]"
            disabled
            sx={{ opacity: 0.8 }}
          >
            <InputLabel
            // sx={{
            //   fontSize: "1.2rem",
            //   marginRight: "16px",
            // }}
            >
              <span>
                Target Layers<span className="text-red-500">*</span>
              </span>
            </InputLabel>
            <Select
              label="Target Layers"
              defaultValue="self_attn"
              sx={{ height: 44 }}
              error={!!errors.targetLayers}
              helperText={errors.targetLayers}
              onChange={(e) => setTargetLayers(e.target.value)}
            >
              <MenuItem value="self_attn">Self Attention</MenuItem>
            </Select>
          </FormControl>

          {/* Learning Rate Field */}
          <TextField
            label={
              <span>
                Learning Rate<span className="text-red-500">*</span>
              </span>
            }
            // InputLabelProps={{
            //   sx: { fontSize: "1.2rem" },
            //   shrink: true,
            // }}
            defaultValue="0.0002"
            className="w-auto xl:w-[470px]"
            error={!!errors.learningRate}
            helperText={errors.learningRate}
            onChange={(e) => setLearningRate(e.target.value)}
          />
        </Box>
      </section>

      {/* Data Configuration */}
      <section className="mb-6 sm:mr-0 md:mr-[15%]">
        <p className="flex items-center space-x-1 mb-6">
          <span className="font-semibold text-base">Data Configuration</span>
          {/* <Tooltip text="Directory name to save the model outputs." /> */}
          {learnMoreLink}
        </p>
        <Box className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-6 mt-4">
          {/* Validation Percent */}
          <FormControl className="w-auto xl:w-[470px]">
            <InputLabel
            // sx={{
            //   fontSize: "1.2rem",
            //   marginRight: "16px",
            // }}
            >
              <span>
                Validation Percent<span className="text-red-500">*</span>
              </span>
            </InputLabel>
            <Select
              label="Validation Percent"
              defaultValue="10"
              sx={{ height: 44 }}
              error={!!errors.validationPercent}
              helperText={errors.validationPercent}
              onChange={(e) => setValidationPercent(e.target.value)}
            >
              <MenuItem value="10">10%</MenuItem>
              <MenuItem value="20">20%</MenuItem>
              <MenuItem value="30">30%</MenuItem>
            </Select>
          </FormControl>

          {/* Data Category */}
          <FormControl className="w-auto xl:w-[470px]">
            <InputLabel
            // sx={{
            //   fontSize: "1.2rem",
            //   marginRight: "16px",
            // }}
            >
              <span>
                Data Category<span className="text-red-500">*</span>
              </span>
            </InputLabel>
            <Select
              label="Data Category"
              defaultValue="qa"
              sx={{ height: 44 }}
              error={!!errors.dataCategory}
              helperText={errors.dataCategory}
              onChange={(e) => setDataCategory(e.target.value)}
            >
              <MenuItem value="qa">Question Answer</MenuItem>
              <MenuItem value="not-qa">Not Question Answer</MenuItem>
            </Select>
          </FormControl>

          {/* User Prompt Field */}
          <TextField
            label="User Prompt"
            // defaultValue="Dummy"
            className="w-auto xl:w-[470px]"
            // InputLabelProps={{
            //   sx: { fontSize: "1.2rem", paddingRight: "1.25rem" },
            //   shrink: true,
            // }}
            InputLabelProps={{
              sx: { padding: -2 },
            }}
            onChange={(e) => setuserPromt(e.target.value)}
          />
        </Box>
      </section>

      {/* Other Section */}
      <section className="pt-2 flex flex-col sm:flex-col md:flex-row justify-between items-center space-y-4 sm:space-y-0 sm:space-x-4">
        <div className="flex flex-col sm:flex-col md:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
          <button
            className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1] text-[#10001ACC] rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[30px] py-[10px]"
            onClick={() => setShowConfirmationModal(true)}
          >
            Launch Notebook
          </button>
          <a
            href="https://www.qubrid.com/how-to-fine-tune-meta-llama-3-on-qubrid-ai-model-studio/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-1"
          >
            <span className="text-[#8F27C1] font-semibold">Advance Users</span>
            <FiExternalLink className="ml-1 text-[#8F27C1] font-semibold" />
          </a>
        </div>
        <div className="flex flex-col sm:flex-col md:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
          <button
            className="hover:bg-[#8F27C11A] hover:border-[#8F27C10D] hover:text-[#8F27C1] text-[#10001ACC] rounded-[12px] border border-[#10001ACC] text-lg font-semibold px-[30px] py-[10px]"
            onClick={() => window.location.reload()}
          >
            Reset Parameter
          </button>
          <button
            className="classic-button h-[45px] w-[143px] text-[18px] font-[600]"
            onClick={handleConfirmation}
            disabled={loading}
          >
            {loading ? "Tuning..." : "Tune"}
          </button>
          {/* <button
            className="classic-button h-[45px] w-[143px] text-[18px] font-[600]"
            onClick={handleTune}
          >
            Tune
          </button> */}
        </div>
      </section>
      {/* Display API Error if any */}
      {apiError && (
        <Box mt={2}>
          <Typography color="error">{apiError}</Typography>
        </Box>
      )}

      {/* Upload CSV File Modal */}
      {isOpen && (
        <FineTuneModelModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          //  onDataSubmit={handleOutputData}
        />
      )}

      {/* launch notebook modal */}
      {showConfirmationModal && (
        <div className="fixed z-[9999] inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Are you sure you want to launch the notebook?
                  </h3>
                  <p className="text-red-500">$2/hr will be deducted.</p>
                </div>
                <div className="mt-5 sm:mt-6 flex justify-center">
                  <button
                    type="button"
                    // className="inline-flex justify-center py-2 px-4 border border-transparent shadow-md text-sm font-medium rounded-md text-white bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
                    className="general-button "
                    onClick={() => {
                      setShowConfirmationModal(false);
                      deployAiModel();
                    }}
                  >
                    Yes, Launch
                  </button>
                  <button
                    type="button"
                    // className="inline-flex justify-center py-2 px-4 border border-transparent shadow-md text-sm font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 ml-3"
                    className="cancel-button  ml-5"
                    onClick={() => setShowConfirmationModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Confirmation Modal */}
      {confirmationOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg shadow-lg p-10 z-50">
            <h2 className="text-lg font-semibold mb-8">
              Are you sure you want to tune?
            </h2>
            <div className="flex justify-end font-normal">
              <button
                className="mr-4 px-4 py-2 bg-gray-200 rounded-lg"
                onClick={handleCancelTune}
              >
                No
              </button>
              <button
                className="px-4 py-2 bg-[#8F27C1] text-white rounded-lg"
                onClick={handleConfirmTune}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Add Credit Modal */}
      <AddCreditModal isOpen={isCreditOpen} setIsOpen={setIsCreditOpen} />
    </div>

  );
};

export default ModelTuning;
